import variables from "./ru/variables.json";
import login_page from "./ru/pages/login.json";
import layout_page from "./ru/pages/layout.json";

import banners_page from "./ru/pages/banners.json";

import courses_page from "./ru/pages/courses/courses.json";
import course_page from "./ru/pages/courses/course.json";
import course_information_page from "./ru/pages/courses/information.json";
import course_content_page from "./ru/pages/courses/content.json";
import course_appointment_page from "./ru/pages/courses/appointment.json";

import groups_page from "./ru/pages/groups/groups.json";
import group_page from "./ru/pages/groups/group.json";
import group_users_page from "./ru/pages/groups/users.json";
import group_tags_page from "./ru/pages/groups/tags.json";
import group_appointment_page from "./ru/pages/groups/appointment.json";

import lesson_page from "./ru/pages/lessons/lesson.json";
import lesson_information_page from "./ru/pages/lessons/information.json";
import lesson_homework_page from "./ru/pages/lessons/homework.json";
import lesson_content_page from "./ru/pages/lessons/content.json";
import lesson_content_article_page from "./ru/pages/lessons/content_article.json";
import lesson_content_card_page from "./ru/pages/lessons/content_card.json";

import news_page from "./ru/pages/news/news.json";
import new_page from "./ru/pages/news/new.json";
import new_content_page from "./ru/pages/news/content.json";
import new_information_page from "./ru/pages/news/information.json";

import events_page from "./ru/pages/events/events.json";
import event_page from "./ru/pages/events/event.json";
// import event_content_page from "./ru/pages/events/content.json";
import event_information_page from "./ru/pages/events/information.json";
import event_members_page from "./ru/pages/events/members.json";

import tags_page from "./ru/pages/tags/tags.json"

import topics_page from "./ru/pages/topics/topics.json"

import tests_page from "./ru/pages/tests/tests.json"
import test_page from "./ru/pages/tests/test.json"
import test_information_page from "./ru/pages/tests/information.json"
import test_questions_page from "./ru/pages/tests/questions.json"

import users_page from "./ru/pages/users/users.json"
import user_page from "./ru/pages/users/user.json"
import user_information_page from "./ru/pages/users/information.json"
import user_assignment_page from "./ru/pages/users/assignment.json"
import user_pushes_page from "./ru/pages/users/pushes.json"

import documents_page from "./ru/pages/documents.json"

import moderation_page from "./ru/pages/moderation/moderation.json"

import setting_page from "./ru/pages/setting.json"

import comp_main_menu from "./ru/components/main_menu.json";
import comp_text_input from "./ru/components/text_input.json";
export default {
  variables: variables,
  login_page: login_page,
  layout_page: layout_page,
  banners_page: banners_page,
  courses_page: courses_page,
  course_page: course_page,
  course_information_page: course_information_page,
  course_content_page: course_content_page,
  course_appointment_page: course_appointment_page,
  groups_page: groups_page,
  group_page: group_page,
  group_users_page: group_users_page,
  group_tags_page: group_tags_page,
  group_appointment_page: group_appointment_page,
  lesson_page: lesson_page,
  lesson_information_page: lesson_information_page,
  lesson_homework_page: lesson_homework_page,
  lesson_content_page: lesson_content_page,
  lesson_content_article_page: lesson_content_article_page,
  lesson_content_card_page: lesson_content_card_page,
  news_page: news_page,
  new_page: new_page,
  new_content_page: new_content_page,
  new_information_page: new_information_page,
  events_page: events_page,
  event_page: event_page,
  event_information_page: event_information_page,
  event_members_page: event_members_page,
  tags_page: tags_page,
  tests_page: tests_page,
  test_page: test_page,
  test_information_page: test_information_page,
  test_questions_page: test_questions_page,
  topics_page: topics_page,
  users_page: users_page,
  user_page: user_page,
  user_information_page: user_information_page,
  user_assignment_page: user_assignment_page,
  user_pushes_page: user_pushes_page,
  documents_page: documents_page,
  moderation_page: moderation_page,
  setting_page: setting_page,
  comp_main_menu: comp_main_menu,
  comp_text_input: comp_text_input
};
